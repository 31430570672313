import React from 'react';
import { Container, Row, Col } from "react-bootstrap";

const CustomerReview = () => {

    return (
    <div id="services">
        <Container className="py-4">
          <Row className="mb-5">
            <Col>
                <h1 className="fs-1 text-center text-uppercase">Наши услуги</h1>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
                <p style={{ fontWeight: 'bold' }}>
                    Компания Rent Car Plus предлагает своим клиентам широкий спектр услуг, связанных с арендой автомобилей. Мы стремимся удовлетворить любые потребности наших клиентов и предоставляем следующие услуги:
                </p>
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px', fontWeight: 'bold' }}>
                    <li>Аренда автомобилей с водителем и без водителя</li>
                    <li>Аренда автомобилей для туристических поездок</li>
                    <li>Аренда авто для деловых встреч и корпоративных мероприятий</li>
                    <li>Краткосрочная и долгосрочная аренда автомобилей</li>
                    <li>Подача автомобиля в удобное для вас место</li>
                </ul>
                <p style={{ fontWeight: 'bold' }}>
                    Мы гарантируем высокое качество наших автомобилей, регулярное техническое обслуживание и прозрачные условия аренды. Наш автопарк включает в себя машины различных классов – от эконом до премиум.
                </p>
                <p style={{ fontWeight: 'bold' }}>
                    Мы всегда готовы предложить нашим клиентам лучшие условия и индивидуальный подход. Заказывайте автомобиль в удобное для вас время и наслаждайтесь комфортом и безопасностью на дороге.
                </p>
            </Col>
          </Row>
        </Container>
    </div>
    );
};

export default CustomerReview;
