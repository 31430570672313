import React from 'react'
import {Container, Row, Col} from "react-bootstrap"
import AboutImage from '../assets/images/about-image.png'


const AboutSection = () => {
    return (
    <div id="about-section">
     <Container>
        <Row className="mt-1 mb-2">
          <Col xs={{span:12, order:"last"}} md={{span:6, order:"first"}}>
            <div className="image_iman">
                <img src={AboutImage} className="about_img" alt="gg" />
            </div>
          </Col>
          <Col xs={{span:12, order:"first"}} md={{span:6, order:"last"}}>
            <div className="mt-2 mb-5">
              <h1 className="text-uppercase fs-1 fw-600">
                  About <span className="primary-color">Us</span>
              </h1>
              <p className="about-text fs-5 m-0">
              Наша компания называется «RentCarPlus» и занимаемся мы арендой автомобилей. 

              Так же можем обеспечить трансфер с аэропорта и обратно. 

              На рынке мы появились сравнительно недавно, но уже успели зарекомендовать себя с положительной стороны и наработать постоянную клиентуру. 

                 Наша основная цель это создание максимального комфорта для потребителей нашего автопроката, что стимулирует в дальнейшем желание возвращаться в Грузию вновь и вновь. 
              </p>
            </div>
          </Col>
        </Row>
     </Container>
    </div>
    );
    };
    export default AboutSection;