import React from 'react';
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Nav, Navbar, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import { IoLocation } from "react-icons/io5";
import { LiaHandsHelpingSolid } from "react-icons/lia";
import { BsTelephoneFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";

const Header = () => {

    const location = useLocation();

    const handleHelpButtonClick = e => {
        e.preventDefault()

        Swal.fire(
            'Do you need help?',
            'You can contact with us 24/7.',
            'question'
        )
    }

    return <>
        {
            !location.pathname.includes("admin") &&
            <header id="header">
                <Navbar bg="black" data-bs-theme="dark" collapseOnSelect expand="lg" className="bg-body-tertiary header-line-1">
                    <Container>
                        <Navbar.Brand as={Link} to="/">RACAR</Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link as={Link} to="/" eventKey="i">Home</Nav.Link>
                                <Nav.Link as={Link} to="/about" eventKey="i">About</Nav.Link>
                                <Nav.Link as={Link} to="/vehicles" eventKey="i">Cars</Nav.Link>
                                <Nav.Link as={Link} to="/client" eventKey="i">Service</Nav.Link>
                                <Nav.Link as={Link} to="/contact" eventKey="i">Contact</Nav.Link>
                            </Nav>
                            <Nav>
                                <Nav.Link as={Link} to="/" onClick={handleHelpButtonClick}>Help <LiaHandsHelpingSolid size="1.25em" className="align-text-bottom" /></Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <Container fluid className="header-line-2 py-md-2 text-white fs-5">
                    <Row className="justify-content-md-center text-center">
                        <Col xs={12} md={8}>
                            <Row>
                                <Col xs={12} md={4}>
                                    <span className="fs-6">
                                        <IoLocation className="header-line-2-icon" />&nbsp;
                                        <a href="https://goo.gl/maps" target="_blank" rel="noopener noreferrer" className="text-white">BATUMI</a>
                                    </span>
                                </Col>
                                <Col xs={12} md={4}>
                                    <span className="fs-6">
                                        <BsTelephoneFill size="0.9em" className="header-line-2-icon" />&nbsp;
                                        <a href="tel:+9955555555" target="_blank" rel="noopener noreferrer" className="text-white">+995 555 55 55 55</a>
                                    </span>
                                </Col>
                                <Col xs={12} md={4}>
                                    <span className="fs-6">
                                        <GrMail className="header-line-2-icon" />&nbsp;
                                        <a href="mailto:racar@gmail.com" target="_blank" rel="noopener noreferrer" className="text-white">racar@gmail.com</a>
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </header>
        }
    </>
};

export default Header;
