import React from 'react';
import { Container, Row, Col } from "react-bootstrap";

const CarSearch = () => {
    return (
        <div id="car-info" style={{ backgroundColor: 'black', color: 'white' }} className="pb-1">
            <Container className="py-5">
                <Row>
                    <Col>
                        <h1 className="fs-2 p-0 mb-2" style={{ color: 'yellow' }}>
                            Уважаемые клиенты!
                        </h1>
                        <p className="fs-5 p-0 m-0 mb-5">
                            Рады представить вам наш автопарк, где вы можете выбрать автомобиль на любой вкус и размер. Широкий выбор авто для аренды: от экономичных седанов и хэтчбеков до комфортабельных минивэнов и внедорожников. У нас есть марки такие как Toyota, Nissan, BMW, Audi, Ford и другие. Наши автомобили имеют высокое качество и регулярно проходят техническое обслуживание, чтобы обеспечить безопасность на дороге и комфорт в путешествии.
                        </p>
                        <p className="fs-5 p-0 m-0 mb-5">
                            Чтобы посмотреть на автомобиль в живую, мы размещаем фотографии на нашем сайте и в социальных сетях или отправим по запросу в личные сообщения.
                        </p>
                        <p className="fs-5 p-0 m-0 mb-5">
                            Мы также ценим мнение клиентов и всегда рады услышать отзыв о наших автомобилях и услугах. Оценки клиентов помогают нам улучшать качество предоставляемых услуг и учитывать все нюансы. Мы также регулярно обновляем автопарк, чтобы предлагать вам только самые современные и надежные автомобили!
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CarSearch;
