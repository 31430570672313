import React from 'react';

import { Container, Row, Col } from "react-bootstrap";

import { AiOutlineSafety } from "react-icons/ai";
import { HiOutlineStatusOnline } from "react-icons/hi";
import { BiSolidOffer } from "react-icons/bi";

const FeaturesSection = () => {
    return (
    <div id="features-section">
        <Container className="py-5">
            <Row>
                <Col>
                  <h1 className="quinary-color fs-1 p-0 mb-4">WHY CHOOSE US</h1>
                </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <AiOutlineSafety size="2.5em" color="white"/>
                <h4 className="quinary-color fs-5 py-1">Safety &amp; Security</h4>
                <p className="quinary-color fs-6 m-0 pb-2">
                  Все наши автомобили проходят регулярное техническое обслуживание, что гарантирует их полную исправность и вашу безопасность на дороге. Мы также защищаем ваши личные данные, предоставляя безопасную платформу для онлайн-бронирования.
                </p>
              </Col>
              <Col xs={4}>
                <HiOutlineStatusOnline size="2.5em" color="white"/>
                <h4 className="quinary-color fs-5 py-1">Online Booking</h4>
                <p className="quinary-color fs-6 m-0 pb-2">
                  Бронируйте автомобиль в любое время и из любого места с помощью нашего удобного онлайн-сервиса. Процесс простой и быстрый, что позволяет вам выбрать автомобиль всего за несколько кликов.
                </p>
              </Col>
              <Col xs={4}>
                <BiSolidOffer size="2.5em" color="white"/>
                <h4 className="quinary-color fs-5 py-1">Best Offers</h4>
                <p className="quinary-color fs-6 m-0 pb-2">
                  Мы предлагаем лучшие цены на рынке и регулярно обновляем акции. Независимо от ваших предпочтений, у нас найдется автомобиль для вас по доступной цене.
                </p>
              </Col>
            </Row>
        </Container>
    </div> 
    );
};
export default FeaturesSection;
