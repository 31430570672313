import React, {useEffect, useState} from 'react';
import {useParams, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';

import {Container, Row, Col, Form, ListGroup, InputGroup, Button } from 'react-bootstrap';

import {TbEngine, TbManualGearbox} from "react-icons/tb";
import {BsCarFront, BsFillCarFrontFill, BsFillFuelPumpFill} from "react-icons/bs";
import {PiEngineFill} from "react-icons/pi";

import {fetchBrands, fetchModels, fetchCars} from "../hooks/useFetchData";

import {loadingContent} from "../components/general/general-components";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {addDoc, collection, doc, setDoc} from "firebase/firestore";
import {db} from "../config/firebase";

import emailjs from 'emailjs-com';

const CarDetail = () => {

    const {carBrand, carModel, carId} = useParams();
    const navigate = useNavigate();

    const [cars, setCars] = useState(null);
    const [brands, setBrands] = useState(null);
    const [models, setModels] = useState(null);

    // Задаем локации статически
    const [locations] = useState({
        1: 'Батуми',
        2: 'Кутаиси'
    });

    // Добавляем состояние для контактов пользователя
    const [userContact, setUserContact] = useState({
        email: '',
        phone: ''
    });

    const [selectedLocations, setSelectedLocations] = useState({pickup: "", dropoff: ""});
    const [rentDate, setRentDate] = useState({start: getDateByInputFormat(), end: getDateByInputFormat(1)});

    const [isReservationTimerEnable, setIsReservationTimerEnable] = useState(true);
    const [reservationTimer, setReservationTimer] = useState(300); // in seconds

    // Добавляем состояние для загрузки
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchBrands().then(response => setBrands(response));
        fetchModels().then(response => setModels(response));
        fetchCars().then(response => {
            setCars(response);
            setIsReservationTimerEnable(response[carId].carCount > 0);
        });
    }, []);

    function getDateByInputFormat(dayOffset= 0, date = null) {
        let currentDate = date === null ? new Date() : new Date(date);
        if(dayOffset === 0) return currentDate.toISOString().split('T')[0];

        const offsetDate = new Date(currentDate);
        offsetDate.setDate(currentDate.getDate() + dayOffset);
        return offsetDate.toISOString().split('T')[0];
    }

    function timerToString() {
        let minutes = ('0' + Math.floor(reservationTimer/60)).slice(-2);
        let seconds = ('0' + reservationTimer%60).slice(-2);
        return minutes + ":" + seconds;
    }

    function handleReserveTimeout() {
        let redirectTimerInterval;
        Swal.fire({
            title: 'Вы не завершили резервацию!',
            html: 'Вы будете перенаправлены через <strong>5</strong> секунд',
            timer: 5000,
            didOpen: () => {
                Swal.showLoading();
                redirectTimerInterval = setInterval(() => {
                    Swal.getHtmlContainer().querySelector('strong')
                        .textContent = (Swal.getTimerLeft() / 1000)
                        .toFixed(0)
                }, 100)
            },
            willClose: () => {
                clearInterval(redirectTimerInterval);
                navigate("/");
            }
        });
    }

    useEffect(() => {
        if(!isReservationTimerEnable) return;

        if(reservationTimer > 0){
            const timerId = setTimeout(()=>{
                setReservationTimer(reservationTimer - 1);
            }, 1000);
            return () => clearTimeout(timerId);
        }
        else {
            handleReserveTimeout();
        }
    }, [reservationTimer]);

    const handleReserveButtonClick = async event => {
        // Проверяем, что выбраны все необходимые данные
        if(Object.values(selectedLocations).some(value => value === "")){
            let resultContent = Object.values(selectedLocations).every(value => value === "")
                ? "Пожалуйста, выберите местоположения!"
                : selectedLocations.pickup === ""
                    ? "Пожалуйста, выберите место получения!"
                    : "Пожалуйста, выберите место возврата!"

            Swal.fire({title: resultContent, icon: "warning"});
            return;
        }

        // Проверяем, что введены контакты пользователя
        if(!userContact.email || !userContact.phone) {
            Swal.fire({title: "Пожалуйста, введите вашу электронную почту и номер телефона!", icon: "warning"});
            return;
        }

        event.currentTarget.disabled = true;
        setIsReservationTimerEnable(false);
        setIsLoading(true);

        const reservationData = {
            reservationOwner: userContact.email,
            carId: parseInt(carId) || 0,
            carBrand: carBrand,
            carModel: carModel,
            startDate: rentDate.start,
            endDate: rentDate.end,
            pickupLocation: selectedLocations.pickup,
            dropoffLocation: selectedLocations.dropoff,
            phone: userContact.phone
        };

        const carsClone = Object.assign({}, cars);
        carsClone[carId].carCount = carsClone[carId].carCount - 1;

        try {
            // Обновляем данные в Firestore
            await setDoc(doc(db, "vehicle", "cars"), carsClone);
            await addDoc(collection(db, "rentals"), reservationData);

            // Отправляем письмо через EmailJS
            const emailParams = {
                email: userContact.email,
                phone: userContact.phone,
                carBrand: carBrand,
                carModel: carModel,
                startDate: rentDate.start,
                endDate: rentDate.end,
                pickupLocation: selectedLocations.pickup,
                dropoffLocation: selectedLocations.dropoff
            };

            await emailjs.send('service_tpqkjkx', 'template_p25b1kb', emailParams, 'AOJNXHXhxbNPn4ig0');

            setIsLoading(false);
            Swal.fire({
                title: "Сообщение отправлено!",
                text: "Мы свяжемся с вами в ближайшее время.",
                icon: "success"
            });
        } catch (error) {
            setIsLoading(false);
            console.error('Ошибка при выполнении резервации:', error);
            Swal.fire({
                icon: "error",
                title: "Ошибка при отправке",
                text: "Попробуйте отправить сообщение позже."
            });
        }
    };

    return (
        <div id="car-detail" style={{clear: "both"}}>
            <Container className="py-4">
                <Row className="mb-5">
                    <Col>
                        {
                            isReservationTimerEnable &&
                            <h1 className="fs-1 text-center text-uppercase">
                                Завершите вашу резервацию за <b>{timerToString()}</b>
                            </h1>
                        }
                    </Col>
                </Row>
                {
                    cars && brands && models && locations
                        ?
                        <>
                            <Row className="mb-4">
                                <Col xs={12} md={6}>
                                    <LazyLoadImage
                                        src={cars[carId].image}
                                        className="img-fluid"
                                        effect="blur"
                                        alt={`${carBrand} / ${carModel}`}
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item variant="secondary" action>
                                            <BsFillCarFrontFill size="2em" className="me-2" style={{marginTop: "-10px"}}/>
                                            <span className="fs-6">Марка & Модель:</span> &nbsp;
                                            <span className="fs-5 fw-bold">{`${carBrand} / ${carModel}`}</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item action>
                                            <TbEngine size="2em" className="me-2" style={{marginTop: "-8px"}}/>
                                            <span className="fs-6">Мощность (HP):</span> &nbsp;
                                            <span className="fs-5 fw-bold">{cars[carId].power}</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item action>
                                            <PiEngineFill size="2em" className="me-2" style={{marginTop: "-8px"}}/>
                                            <span className="fs-6">Объем двигателя:</span> &nbsp;
                                            <span className="fs-5 fw-bold">{cars[carId].engineSize}</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item action>
                                            <TbManualGearbox size="2em" className="me-2" style={{marginTop: "-8px"}}/>
                                            <span className="fs-6">Коробка передач:</span> &nbsp;
                                            <span className="fs-5 fw-bold">{cars[carId].gearbox}</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item action>
                                            <BsCarFront size="2em" className="me-2" style={{marginTop: "-10px"}}/>
                                            <span className="fs-6">Тип кузова:</span> &nbsp;
                                            <span className="fs-5 fw-bold">{cars[carId].bodyType}</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item action>
                                            <BsFillFuelPumpFill size="2em" className="me-2" style={{marginTop: "-10px"}}/>
                                            <span className="fs-6">Тип топлива:</span> &nbsp;
                                            <span className="fs-5 fw-bold">{cars[carId].fuelType}</span>
                                        </ListGroup.Item>
                                    </ListGroup>

                                    <div className="text-end">
                                        <span className={`text-secondary fst-italic ${cars[carId].carCount > 0 ? "text-success" : "text-danger"}`}>
                                            Доступно: {cars[carId].carCount}
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <InputGroup size="lg" className="my-2">
                                        <InputGroup.Text id="pick-up-locations">Место получения</InputGroup.Text>
                                        <Form.Select
                                            name="pick-up-locations" size="lg"
                                            defaultValue={selectedLocations.pickup}
                                            onChange={e => {
                                                setSelectedLocations(prevState => ({
                                                    ...prevState,
                                                    pickup: e.target.value
                                                }));
                                            }}
                                        >
                                            <option value="">Выберите местоположение...</option>
                                            {
                                                Object.entries(locations).map(([key, value]) =>
                                                    <option key={key} value={value}>{value}</option>
                                                )
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                                <Col xs={12} md={6}>
                                    <InputGroup size="lg" className="my-2">
                                        <InputGroup.Text id="start-date">Дата начала</InputGroup.Text>
                                        <Form.Control
                                            type="date"
                                            min={getDateByInputFormat()}
                                            name="start-date"
                                            placeholder="Дата начала"
                                            value={rentDate.start}
                                            onKeyDown={e => e.preventDefault()}
                                            onChange={e => {
                                                setRentDate({
                                                    start: e.target.value,
                                                    end: getDateByInputFormat(1, e.target.value)
                                                })
                                            }}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <InputGroup size="lg" className="my-2">
                                        <InputGroup.Text id="drop-off-locations">Место возврата</InputGroup.Text>
                                        <Form.Select
                                             name="drop-off-locations" size="lg"
                                             defaultValue={selectedLocations.dropoff}
                                             onChange={e => {
                                                 setSelectedLocations(prevState => ({
                                                     ...prevState,
                                                     dropoff: e.target.value
                                                 }));
                                             }}
                                        >
                                            <option value="">Выберите местоположение...</option>
                                            {
                                                Object.entries(locations).map(([key, value]) =>
                                                    <option key={key} value={value}>{value}</option>
                                                )
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                                <Col xs={12} md={6}>
                                    <InputGroup size="lg" className="my-2">
                                        <InputGroup.Text id="end-date">Дата окончания</InputGroup.Text>
                                        <Form.Control
                                            type="date"
                                            min={getDateByInputFormat(1, rentDate.start)}
                                            name="end-date"
                                            placeholder="Дата окончания"
                                            value={rentDate.end}
                                            onKeyDown={e => e.preventDefault()}
                                            onChange={e => {
                                                setRentDate(prevState => ({
                                                    ...prevState,
                                                    end: e.target.value
                                                }));
                                            }}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            {/* Добавляем поля для ввода электронной почты и номера телефона */}
                            <Row>
                                <Col xs={12} md={6}>
                                    <InputGroup size="lg" className="my-2">
                                        <InputGroup.Text id="email">Электронная почта</InputGroup.Text>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            placeholder="Введите вашу электронную почту"
                                            value={userContact.email}
                                            onChange={e => {
                                                setUserContact(prevState => ({
                                                    ...prevState,
                                                    email: e.target.value
                                                }));
                                            }}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={12} md={6}>
                                    <InputGroup size="lg" className="my-2">
                                        <InputGroup.Text id="phone">Номер телефона</InputGroup.Text>
                                        <Form.Control
                                            type="tel"
                                            name="phone"
                                            placeholder="Введите ваш номер телефона"
                                            value={userContact.phone}
                                            onChange={e => {
                                                setUserContact(prevState => ({
                                                    ...prevState,
                                                    phone: e.target.value
                                                }));
                                            }}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button variant="success" size="lg" className="w-100 fs-4 fw-bold"
                                            type="button"
                                            onClick={handleReserveButtonClick}
                                            disabled={cars[carId].carCount <= 0 || isLoading}>
                                        {isLoading ? "Загрузка..." : "Reserve Now!"}
                                    </Button>
                                </Col>
                            </Row>
                        </>
                        :
                        loadingContent
                }
            </Container>
        </div>
    );
};

export default CarDetail;
