import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import emailjs from 'emailjs-com';  // Импортируем EmailJS
import Swal from 'sweetalert2';

const ContactSection = () => {
    const [formData, setFormData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    // Обработчик изменений в форме
    const handleFormChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    // Обработчик отправки формы
    const handleContactSubmit = (event) => {
        event.preventDefault();

        setIsLoading(true);

        const emailParams = {
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            message: formData.message
        };

        // Отправка email через EmailJS
        emailjs.send('service_tpqkjkx', 'template_ibjdicp', emailParams, 'AOJNXHXhxbNPn4ig0')
            .then(() => {
                setIsLoading(false);
                Swal.fire({
                    title: "Сообщение отправлено!",
                    text: "Мы свяжемся с вами в ближайшее время.",
                    icon: "success"
                });
            })
            .catch((err) => {
                setIsLoading(false);
                Swal.fire({
                    icon: "error",
                    title: "Ошибка при отправке",
                    text: "Попробуйте отправить сообщение позже."
                });
                console.error("Ошибка при отправке:", err);
            });
    };

    return (
        <div id="contact-section">
            <Container className="pt-4">
                <Row className="mb-5">
                    <Col>
                        <h1 className="fs-1 text-center text-uppercase">Свяжитесь с нами</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="primary-bg-color py-5 rounded-top">
                            <Row className="justify-content-center">
                                <Col xs={8} className="text-center">
                                    {!isLoading ? (
                                        <Form onSubmit={handleContactSubmit}>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                placeholder="Имя"
                                                className="mb-2"
                                                onChange={handleFormChange}
                                                required
                                            />
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                placeholder="Email"
                                                className="mb-2"
                                                onChange={handleFormChange}
                                                required
                                            />
                                            <Form.Control
                                                type="tel"
                                                name="phone"
                                                placeholder="Телефон"
                                                className="mb-2"
                                                onChange={handleFormChange}
                                                required
                                            />
                                            <Form.Control
                                                as="textarea"
                                                name="message"
                                                rows={3}
                                                placeholder="Сообщение"
                                                className="mb-2"
                                                onChange={handleFormChange}
                                                required
                                            />
                                            <div className="d-grid">
                                                <Button
                                                    variant="secondary"
                                                    className="border-0 py-2"
                                                    type="submit"
                                                >
                                                    Отправить
                                                </Button>
                                            </div>
                                        </Form>
                                    ) : (
                                        <p>Отправка...</p>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ContactSection;
